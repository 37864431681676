import { render, staticRenderFns } from "./Presentation.vue?vue&type=template&id=654b0eb3&scoped=true"
import script from "./Presentation.vue?vue&type=script&lang=js"
export * from "./Presentation.vue?vue&type=script&lang=js"
import style0 from "./Presentation.vue?vue&type=style&index=0&id=654b0eb3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "654b0eb3",
  null
  
)

export default component.exports