<template>
  <div class="wrapper presentation-page">
    <div class="page-header clear-filter">
      <div class="rellax-header rellax-header-sky">
        <parallax
          class="page-header-image"
          style="background-image: url('img/hero1.jpg')"
        />
      </div>

      <div class="rellax-text-container rellax-text">
        <h1
          class="h1-seo"
          data-rellax-speed="-2"
        >
          Big Curt
        </h1>
        <!-- <div class="pro">
          PRO
        </div> -->
      </div>
      <h3
        class="h3-description rellax-text"
        data-rellax-speed="-1"
      >
        Charitable Foundation
      </h3>
      <h6
        class="category category-absolute rellax-text"
        data-rellax-speed="-2"
      >
        <parallax>
          McClendon
          Curtis
        </parallax>
      </h6>
    </div>
    <!-- <div
      class="section section-components"
      data-background-color="dark-red"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto">
            <h2 class="text-center title">
              Mission Statement
            </h2>
            <h5 class="text-center description">
              The mission of The Big Curt Charitable Foundation is to empower youth to higher
              achievement in education, health and personal wellness.
            </h5>
          </div>
        </div>
      </div>
    </div> -->
    <div class="section section-basic-components">
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-7 ml-auto mr-auto">
            <h2 class="title">
              Mission Statement
            </h2>
            <h6 class="category">
              At Our Core
            </h6>
            <h5 class="description">
              The mission of The Big Curt Charitable Foundation is to empower youth to higher
              achievement in education, health and personal wellness.
            </h5>
          </div>
        </div>
      </div>
    </div>

    <div
      class="section section-content"
      data-background-color="black"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-5">
            <div class="image-container">
              <img
                class="img img-raised rounded img-comments rellax"
                data-rellax-speed="1"
                src="img/camp-images/2v.jpg"
                alt="content 1"
              >
              <img
                class="img img-raised rounded img-blog rellax"
                data-rellax-speed="4"
                src="img/camp-images/1v.jpg"
                alt="content 4"
              >
            </div>
          </div>
          <div class="col-md-4 ml-auto mr-auto">
            <div class="section-description">
              <h2 class="title">
                About Us
              </h2>
              <h6 class="category">
                What we are about
              </h6>
              <h5 class="description">
                The Big Curt nonprofit foundation was founded by McClendon Curtis and its originators
                in 2024. The foundation provides positive resources and opportunities to children and youth that are designed to yield successful adults. Lack of opportunity can have a profound effect on individuals and groups. The result can be far reaching and devastating. According to the United Nations Inequality - Bridging the Gap inequality-un.org, It can “discourage skills accumulation, choke economic and social mobility, and human development, and consequently, depress economic growth”. Without opportunity, some youth can fall behind. The Big Curt Charitable Foundation is determined that every child whose life we touch reaches their full potential. Through education and tutoring, reading labs, youth employment opportunities, enhancing social skills, mentoring, positive role models and of course, organized activities like sports, we want to ensure that they are afforded every opportunity for success.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="about-team team-4">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">
              Meet The Team
            </h2>
            <h4 class="description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Deleniti voluptas laborum impedit dolor. Quibusdam magni ratione labore. Quam numquam ea reiciendis corrupti quas. Odio asperiores tempora nulla odit, maiores ad.
            </h4>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
            <card
              type="profile"
              plain
            >
              <div
                slot="raw-content"
                class="row"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <a href="#pablo">
                      <img
                        class="img img-raised rounded"
                        src="img/team/mcclendon.jpg"
                      >
                    </a>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">
                      McClendon Curtis
                    </h4>
                    <h6 class="category">
                      Founder
                    </h6>
                    <p class="card-description">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis quibusdam mollitia sapiente ut reprehenderit ratione, vitae alias tenetur esse! Fuga quis eligendi veritatis praesentium explicabo.
                    </p>
                    <div class="card-footer">
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-twitter"
                      ><i
                        class="fab fa-twitter"
                      /></a>
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-facebook"
                      ><i
                        class="fab fa-facebook-square"
                      /></a>
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-google"
                      ><i
                        class="fab fa-google"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
            <card
              type="profile"
              plain
            >
              <div
                slot="raw-content"
                class="row"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <a href="#pablo">
                      <img
                        class="img img-raised rounded"
                        src="img/emily.jpg"
                      >
                    </a>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">
                      Tavis Spotts
                    </h4>
                    <h6 class="category">
                      Insert Role
                    </h6>
                    <p class="card-description">
                      Lorem ipsum dolor, sit amet consectetur adipisicing elit. Mollitia deserunt expedita modi iure omnis quisquam vero veritatis veniam optio voluptate!.
                    </p>
                    <div class="card-footer">
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-linkedin"
                      ><i
                        class="fab fa-linkedin"
                      /></a>
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-facebook"
                      ><i
                        class="fab fa-facebook-square"
                      /></a>
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-dribbble"
                      ><i
                        class="fab fa-dribbble"
                      /></a>
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-google"
                      ><i
                        class="fab fa-google"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
            <card
              type="profile"
              plain
            >
              <div
                slot="raw-content"
                class="row"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <a href="#pablo">
                      <img
                        class="img img-raised rounded"
                        src="img/james.jpg"
                      >
                    </a>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">
                      Nikki Bryant
                    </h4>
                    <h6 class="category">
                      Insert Role
                    </h6>
                    <p class="card-description">
                      Lorem, ipsum dolor sit amet consectetur adipisicing elit. Hic, maiores repudiandae! Unde, molestiae culpa sit perferendis ut tempore quis, impedit quod fugit adipisci quia ratione....
                    </p>
                    <div class="card-footer">
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-youtube"
                      ><i
                        class="fab fa-youtube"
                      /></a>
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-twitter"
                      ><i
                        class="fab fa-twitter"
                      /></a>
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-instagram"
                      ><i
                        class="fab fa-instagram"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
          
          <div class="col-xl-6 col-lg-7 ml-auto mr-auto">
            <card
              type="profile"
              plain
            >
              <div
                slot="raw-content"
                class="row"
              >
                <div class="col-md-5">
                  <div class="card-image">
                    <a href="#pablo">
                      <img
                        class="img img-raised rounded"
                        src="img/michael.jpg"
                      >
                    </a>
                  </div>
                </div>
                <div class="col-md-7">
                  <div class="card-body">
                    <h4 class="card-title">
                      Greg Spotts
                    </h4>
                    <h6 class="category">
                      Insert Role
                    </h6>
                    <p class="card-description">
                      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aperiam debitis cumque illum dolorem minima ad explicabo incidunt? Et, magnam! Debitis.
                    </p>
                    <div class="card-footer">
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-linkedin"
                      ><i
                        class="fab fa-linkedin"
                      /></a>
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-facebook"
                      ><i
                        class="fab fa-facebook-square"
                      /></a>
                      <a
                        href="#pablo"
                        class="btn btn-icon btn-neutral btn-google"
                      ><i
                        class="fab fa-google"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
    </div>
    <div
      class="section section-sections"
      data-background-color="gray"
    >
      <div class="container">
        <div class="col-md-8 ml-auto mr-auto">
          <div class="section-description text-center">
            <h2 class="title">
              Donations
            </h2>
            <h5 class="description">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus quis obcaecati dicta perspiciatis ut libero asperiores molestias hic maxime, non magni nostrum! Ipsam sint at impedit harum corrupti alias tempora voluptatem illo expedita enim, eligendi obcaecati minus animi voluptas ab soluta officiis ullam error maiores repudiandae veritatis natus quasi eveniet nobis? Exercitationem quam voluptate voluptates repellendus iusto recusandae ad vitae, molestiae tenetur odio veniam repellat aspernatur inventore, odit itaque doloremque reiciendis soluta cupiditate. Tempore dolore voluptas culpa, voluptate nihil aspernatur. Quis iste ratione nostrum consequatur vero repudiandae enim porro amet architecto, hic illo reiciendis maxime doloremque odio voluptatibus reprehenderit minus aliquam corporis doloribus ut. Facere, soluta delectus eum, impedit, laborum officia quo saepe eius provident corrupti ab nobis? Quidem, dolor!
            </h5>
            <a
              href="https://donate.stripe.com/test_14k5nX9AP76O4nK4gg"
              target="_blank"
              class="btn btn-primary btn-round"
            >Donate</a>
          </div>
        </div>
      </div>
    </div>
    
    <!-- <div
      class="section section-examples"
      data-background-color="black"
    >
      <div class="container">
        <div class="col-md-8 ml-auto mr-auto text-center">
          <div class="section-description">
            <h2 class="title">
              Example Pages
            </h2>
            <h5 class="description">
              Forget about building everything from scratch. From landing pages to
              e-commerce or blog pages, you will be able jump start your development. Show your clients a
              quick prototype and get inspired for your next project!
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <h5 class="title">
              About Us
            </h5>
            <div class="card">
              <router-link
                to="/about"
                target="_blank"
              >
                <img
                  v-lazy="'img/presentation-page/pages/about-us.jpg'"
                  alt=""
                >
              </router-link>
            </div>
            <h5 class="title">
              Landing Page
            </h5>
            <div class="card">
              <router-link
                to="/landing"
                target="_blank"
              >
                <img
                  v-lazy="'img/presentation-page/pages/landing.jpg'"
                  alt=""
                >
              </router-link>
            </div>
            <h5 class="title">
              Login Page
            </h5>
            <div class="card">
              <router-link
                to="/login"
                target="_blank"
              >
                <img
                  v-lazy="'img/presentation-page/pages/login.jpg'"
                  alt=""
                >
              </router-link>
            </div>
            <h5 class="title">
              Signup Page
            </h5>
            <div class="card">
              <router-link
                to="/signup"
                target="_blank"
              >
                <img
                  v-lazy="'img/presentation-page/pages/signup.jpg'"
                  alt=""
                >
              </router-link>
            </div>
          </div>
          <div class="col-md-4">
            <h5 class="title">
              Blog Post
            </h5>
            <div class="card">
              <router-link
                to="/blog-post"
                target="_blank"
              >
                <img
                  v-lazy="'img/presentation-page/pages/blog-post.jpg'"
                  alt=""
                >
              </router-link>
            </div>
            <h5 class="title">
              Product Page
            </h5>
            <div class="card">
              <router-link
                to="/product"
                target="_blank"
              >
                <img
                  v-lazy="'img/presentation-page/pages/product.jpg'"
                  alt=""
                >
              </router-link>
            </div>
            <h5 class="title">
              Profile Page
            </h5>
            <div class="card">
              <router-link
                to="/profile"
                target="_blank"
              >
                <img
                  v-lazy="'img/presentation-page/pages/profile.jpg'"
                  alt=""
                >
              </router-link>
            </div>
          </div>
          <div class="col-md-4">
            <h5 class="title">
              Pricing Page
            </h5>
            <div class="card">
              <router-link
                to="/pricing"
                target="_blank"
              >
                <img
                  v-lazy="'img/presentation-page/pages/pricing.jpg'"
                  alt=""
                >
              </router-link>
            </div>
            <h5 class="title">
              Ecommerce
            </h5>
            <div class="card">
              <router-link
                to="/ecommerce"
                target="_blank"
              >
                <img
                  v-lazy="'img/presentation-page/pages/ecommerce.jpg'"
                  alt=""
                >
              </router-link>
            </div>
            <h5 class="title">
              Blog Posts
            </h5>
            <div class="card">
              <router-link
                to="/blog-posts"
                target="_blank"
              >
                <img
                  v-lazy="'img/presentation-page/pages/blog-posts.jpg'"
                  alt=""
                >
              </router-link>
            </div>
            <h5 class="title">
              Contact Us
            </h5>
            <div class="card">
              <router-link
                to="/contact"
                target="_blank"
              >
                <img
                  v-lazy="'img/presentation-page/pages/contact.jpg'"
                  alt=""
                >
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div
      class="section features-7 section-image"
      style="background-image: url('img/bg32.jpg')"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-6 px-0">
            <div class="col-sm-12">
              <info-section
                title="Save Time & Money"
                icon="now-ui-icons business_money-coins"
                description="Creating your design from scratch with dedicated designers
                                        can be very expensive.Using Now UI Kit PRO you don't have to worry about
                                        customising the basic Bootstrap 4 design or its components."
              />

              <info-section
                title="Bootstrap 4 & Flexbox"
                icon="now-ui-icons text_bold"
                description="It is built over Bootstrap 4, it's fully responsive and has
                                        all the benefits of the flexbox for the layout, grid system and components. This
                                        is a huge advantage when you work with columns."
              />

              <info-section
                title="Fast Prototype"
                icon="now-ui-icons education_paper"
                description="Using Now UI Kit PRO you can create hundreds of components
                                        combinations within seconds and present them to your client. You just need to
                                        change some classes and colors."
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="image-container">
              <img
                v-lazy="'img/ipad3.png'"
                alt=""
              >
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
  import { Card, InfoSection } from '@/components';
  import { Carousel, CarouselItem, Tooltip } from 'element-ui';
  import Rellax from "rellax";

  export default {
    name: 'Presentation',
    bodyClass: 'presentation-page',
    components: {
      Card,
      InfoSection,
      [Carousel.name]: Carousel,
      [CarouselItem.name]: CarouselItem,
      [Tooltip.name]: Tooltip
    },
    mounted() {
      if (window.innerWidth >= 991) {

        setTimeout(function() {
          var rellax = new Rellax('.rellax', {
            center: true
          });
        }, 5000);

        var rellaxHeader = new Rellax('.rellax-header');
        var rellaxText = new Rellax('.rellax-text');
      }

    }
  }
</script>

<style scoped lang="scss">
    .github-buttons {
        display: flex;
        align-content: center;
    }
</style>
